<template>
  <CCard class="mt-3">
    <CCardBody
      border-color="greyborder"
      style="border-radius: 15px"
      class="text-center"
    >
      <img
        src="../../../public/img/error.png"
        class="img-fluid"
        width="40%"
        alt=""
      />
      <h4 class="mt-4 font-weight-bold">ชำระเงินไม่สำเร็จ</h4>
      <br />
      <table
        style="width: 100%; border-bottom: 1px solid #e5e5e5"
        class="table text-left small"
      >
        <tbody>
          <tr v-for="list in cartLists" :key="list.objectId">
            <td style="width: 20%">
              <img
                style="border-radius: 3px"
                :src="list.remoteImagePath"
                alt=""
                class="img-fluid"
              />
            </td>
            <td>
              {{ list.productSKU.SKUName }}
              <br />
              <small class="text-description">
                {{ list.quantitySKU }} x {{ list.productSKU.SKUPrice }}
              </small>
            </td>
            <td class="text-right">฿{{ list.totalAmount }}</td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%" class="text-left small">
        <tbody>
          <tr>
            <td style="padding-left: 5px">รวม</td>
            <td class="text-right">฿ {{ amount }}</td>
          </tr>
          <tr>
            <td style="padding-left: 5px">ส่วนลด</td>
            <td class="text-right">-฿0.00</td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right">
              <b> ฿ {{ amount }}</b>
            </td>
          </tr>
        </tbody>
      </table>

      <hr />

      <button class="btn btn-danger btn-block mt-1" @click="goToPayment()">
        ชำระเงิน {{ amount }} THB
      </button>
      <button
        class="btn btn-outline-dark btn-block mt-1"
        @click="clearMyCart()"
      >
        ยกเลิกคำสั่งซื้อ
      </button>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'

export default {
  data() {
    return {
      getAmount: 0,
      cartLists: [],
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    amount() {
      let total = Number(this.getAmount)
      return util.convertCurrency(total)
    },
  },
  mounted() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getAmount = sessionStorage.getItem('amount')
  },
  methods: {
    goToPayment() {
      sessionStorage.setItem('amount', Number(this.amount))
      this.$router.push('/payment')
    },
    clearMyCart() {
      sessionStorage.clear()
      this.$router.push('/pickup/menulists')
    },
  },
}
</script>
